

































































































































































































import { defineComponent, computed, ref } from '@vue/composition-api';
import { usePlans, getIntervalText, PlanProperty } from '@/admin/plan';
import { SUBSCRIPTION_PLAN_STATUS } from '@/admin/payment';
import { DisplayDate } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcDate from '@/components/FcDate.vue';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Plans',
  components: {
    FcRoleLoading,
    FcDate,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('plans'));
    const isShippingAddress: boolean = process.env.VUE_APP_IS_SHIPPING === 'true';

    const { plans } = usePlans();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: 'ステータス', value: 'subscriptionPlanStatus' },
      { text: 'グループ', value: 'subscriptionProductName' },
      { text: 'プラン名', value: 'subscriptionPlanName' },
      { text: '初回料金', value: 'initialSettings' },
      { text: '価格', value: 'price' },
      { text: '期間', value: 'interval' },
      { text: '加入者数上限', value: 'maxSubscribersCount' },
      { text: '閲覧期間', value: 'accessDate', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const isDisplayArchivePlan = ref(false);
    const displayPlans = computed(() =>
      isDisplayArchivePlan.value ? plans.items : plans.items.filter((plan) => !plan.isArchive)
    );

    const archiveSubscriptionPlanInstantly = async (subscriptionPlanId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      plans.isSaving = true;
      try {
        await plans.delete(subscriptionPlanId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
      plans.isSaving = false;
    };
    const isOpenArchiveDialog = ref(false);
    const scheduledArchiveDate = ref<DisplayDate>();
    const openArchiveDialog = (subscriptionPlan: PlanProperty) => {
      scheduledArchiveDate.value = subscriptionPlan.scheduledArchiveDate;
      isOpenArchiveDialog.value = true;
    };
    const closeArchiveDialog = () => {
      scheduledArchiveDate.value = new DisplayDate();
      isOpenArchiveDialog.value = false;
    };
    const archiveSubscriptionPlanBySchedule = async (subscriptionPlanId: string) => {
      if (!scheduledArchiveDate.value || !scheduledArchiveDate.value.value) {
        notification.error('日付を選択してください。');
        return;
      }
      plans.isSaving = true;
      try {
        await plans.createScheduleForArchive(subscriptionPlanId, scheduledArchiveDate.value.value);
        const refreshPlans = true;
        await plans.init(refreshPlans);
        notification.notify('アーカイブ予約を行いました。');
        scheduledArchiveDate.value = new DisplayDate();
        isOpenArchiveDialog.value = false;
      } catch (error) {
        notification.error(error);
      }
      plans.isSaving = false;
    };
    const deleteScheduleForArchiveSubscriptionPlan = async (subscriptionPlanId: string) => {
      if (!(await confirmDialog('本当に取り消しますか？'))) return;
      plans.isSaving = true;
      try {
        await plans.deleteScheduleForArchive(subscriptionPlanId);
        const refreshPlans = true;
        await plans.init(refreshPlans);
        notification.notify('アーカイブ予約を取り消しました。');
        isOpenArchiveDialog.value = false;
      } catch (error) {
        notification.error(error);
      }
      plans.isSaving = false;
    };

    const init = () => {
      if (isShippingAddress) {
        headers.splice(6, 0, { text: 'お届け先情報', value: 'isRequiredShippingAddress' });
      }
    };

    init();

    return {
      pageTitle: 'プラン一覧',
      myRoleSettings,
      isShippingAddress,
      plans,
      headers,
      SUBSCRIPTION_PLAN_STATUS,
      scheduledArchiveDate,
      isOpenArchiveDialog,
      getIntervalText,
      openArchiveDialog,
      closeArchiveDialog,
      archiveSubscriptionPlanInstantly,
      archiveSubscriptionPlanBySchedule,
      deleteScheduleForArchiveSubscriptionPlan,
      displayPlans,
      isDisplayArchivePlan,
    };
  },
});
